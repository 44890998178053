/** 
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui icons
import { Feed, GitHub, Person, SportsEsports } from "@mui/icons-material";

// Pages
import { AboutPage } from "../Pages/AboutPage";
import BlogBigLapStopwatch from "../Pages/Blog/2020/BlogBigLapStopwatch";
import SnakevsBunniesPostMortem from "../Pages/Blog/2021/SnakevsBunniesPostMortem";
import SnakevsBunniesRelease from "../Pages/Blog/2021/SnakevsBunniesRelease";
import BigLapStopwatchOpensource from "../Pages/Blog/2023/BigLapStopwatchOpensource";
import PaintMatchPostMortem from "../Pages/Blog/2023/PaintMatchPostMortem";
import PaintMatchRelease from "../Pages/Blog/2023/PaintMatchRelease";
import WASD2024 from "../Pages/Blog/2024/WASD2024";
import BigLapStopwatch from "../Pages/Portfolio/BigLapStopwatch";
import GlowBlitz from "../Pages/Portfolio/GlowBlitz";
import PaintMatch from "../Pages/Portfolio/PaintMatch";
import SnakeVsBunnies from "../Pages/Portfolio/SnakeVsBunnies";
import BackToGameDev from "../Pages/Blog/2025/BackToGameDev";
import Fracturix from "../Pages/Portfolio/Fracturix";

export const FracturixRoute = {
    name: "Fracturix",
    route: "/games/Fracturix",
    component: <Fracturix />,
};

export const GlowBlitzRoute = {
    name: "GlowBlitz",
    route: "/games/GlowBlitz",
    component: <GlowBlitz />,
};

export const PaintMatchRoute = {
    name: "Paint Match",
    route: "/games/PaintMatch",
    component: <PaintMatch />,
};

export const SnakeVsBunniesRoute =                     {
    name: "Snake vs Bunnies",
    route: "/games/SnakeVsBunnies",
    component: <SnakeVsBunnies />,
};

export const BigLapStopwatchRoute =                     {
    name: "Big Lap Stopwatch",
    route: "/apps/BigLapStopwatch",
    component: <BigLapStopwatch />,
};

export const AboutRoute = {
    name: "About",
    icon: <Person />,
    route: "/about",
    component: <AboutPage />,
};

export const PaintMatchPostMortemRoute = {
    name: "Paint Match post mortem",
    route: "/blog/PaintMatchPostMortem",
    component: <PaintMatchPostMortem />,
};

export const PaintMatchReleaseRoute = {
    name: "Paint Match release",
    route: "/blog/PaintMatchRelease",
    component: <PaintMatchRelease />,
};

export const WASD2024Route = {
    name: "WASD 2024: Most intriguing games",
    route: "/blog/WASD2024",
    component: <WASD2024 />,
};

export const BackToGameDevRoute = {
    name: "Coming back to gamedev after a break",
    route: "/blog/BackToGameDev",
    component: <BackToGameDev />,
};

export const SnakevsBunniesPostMortemRoute = {
    name: "Snake vs Bunnies post mortem",
    route: "/blog/SnakevsBunniesPostMortem",
    component: <SnakevsBunniesPostMortem />,
};

export const SnakevsBunniesReleaseRoute = {
    name: "Snake vs Bunnies release",
    route: "/blog/SnakevsBunniesRelease",
    component: <SnakevsBunniesRelease />,
};

export const BlogBigLapStopwatchReleaseRoute = {
    name: "Big Lap Stopwatch Android app",
    route: "/blog/BlogBigLapStopwatchRelease",
    component: <BlogBigLapStopwatch />,
};

export const BlogBigLapStopwatchOpensourceRoute = {
    name: "Big Lap Stopwatch Open Source",
    route: "/blog/BlogBigLapStopwatchOpensource",
    component: <BigLapStopwatchOpensource />,
};

export const MenuRoutes = [
    {
        name: "Blog",
        icon: <Feed />,
        rowsPerColumn: 1,
        collapse: [
            BackToGameDevRoute,
            WASD2024Route,
            BlogBigLapStopwatchOpensourceRoute,
            PaintMatchPostMortemRoute,
            PaintMatchReleaseRoute,
            SnakevsBunniesPostMortemRoute,
            SnakevsBunniesReleaseRoute,
            BlogBigLapStopwatchReleaseRoute,
        ],
    },
    {
        name: "Portfolio",
        icon: <SportsEsports />,
        columns: 1,
        rowsPerColumn: 2,
        collapse: [
            {
                name: "Games",
                collapse: [
                    FracturixRoute,
                    GlowBlitzRoute,
                    PaintMatchRoute,
                    SnakeVsBunniesRoute,
                ],
            },
            {
                name: "Apps",
                collapse: [
                    BigLapStopwatchRoute,
                ],
            },
        ],
    },
    AboutRoute,
    {
        spacer: true,
    },
    {
        name: "github",
        icon: <GitHub />,
        href: "https://www.github.com/ombosoft",
    },
];
