// eslint-disable-next-line import/no-unresolved
import screenshot from "src/images/Fracturix/screenshot.gif";
import scroll from "src/images/Fracturix/scroll.gif";

import ItchIoBadge from "../../Components/ItchIoBadge";
import PortfolioPage from "../../Components/PortfolioPage";
import Bullets from "../../Components/Typography/Bullets";
import Paragraph from "../../Components/Typography/Paragraph";
import MKTypography from "../../material-kit/MKTypography";
import {FracturixRoute} from "../../Routes/MenuRoutes";
import ResponsibleImage from "../../Components/ResponsibleImage";

export default function Fracturix({headerOnly}) {
    return (
        <PortfolioPage
            title="Fracturix"
            subtitle="Roguelite Arkanoid-like Sidescroller"
            description="Upgrade things to ridiculous proportions"
            screenshot={scroll}
            videoId="GgevcC2Ym58?si=FlAHY18e9ntDMW6C"
            route={FracturixRoute}
            headerOnly={headerOnly}
        >
            <Paragraph>
                Break glass bricks to explore a continuous, procedurally-generated world. Juggle multiple balls at once,
                or capture them back with a magnet powerup. Aim precisely where you want, or just shoot guns akimbo
                straight from your paddle. Balls can shoot, too, with the right powerup! Grow your balls to absurd sizes
                to smash half the screen in one hit, or unlock bombs to clean the level up. Or, why not both?
            </Paragraph>
            <ResponsibleImage src={screenshot} alt="inserters" para/>
            <MKTypography variant="h3" component="div" pt={2}>
                Roguelite
            </MKTypography>
            <Paragraph>
                As you play, unlock tons of upgrades that improve starting conditions, increase the spawn rate and
                potency of powerups, or even regenerate your balls. Freely combine these upgrades to suit your play
                style, or experiment with crazy combos.
            </Paragraph>
            <MKTypography variant="h3" component="div" pt={2}>
                Arkanoid-like
            </MKTypography>
            <Paragraph>
                As a kid, I played many different variants of Arkanoid. My favorite was called Popcorn, but I also
                remember one that had paddle on the side instead of bottom. In this game, I combined my nostalgy for the
                classic game with more modern ideas, and added many original features.
            </Paragraph>
            <MKTypography variant="h3" component="div" pt={2}>
                Procedurally generated
            </MKTypography>
            <Paragraph>
                I combine fractal noise, wave function collapse with a set of randomly shuffled pre-defined fragments to
                generate unique side scrolling level on every run. Bricks are made of glass with a custom shader that
                creates unique shape for every brick.
            </Paragraph>
            <ItchIoBadge itchId={3291711}/>
            <MKTypography variant="h3" component="div" pt={2}>
                Credits
            </MKTypography>
            <Bullets>
                <li>
                    Game design and coding by <b>Shurick (Ombosoft)</b>
                </li>
                <li>
                    Music by <b>tidnmusic</b>
                </li>
            </Bullets>
        </PortfolioPage>
    );
}
